import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CartContext from "../../context/cart";
import Item from "../../components/cart/Item";
import css from "./Cart.module.css";

const Cart = () => {
  const { cart } = useContext(CartContext);
  let price = cart.reduce((acc, curr) => {
    return acc + curr.specifics.total;
  }, 0);

  if (cart.length > 0) {
    return (
      <div className={css.main}>
        <h1>your items</h1>
        <ul className={css.list}>
          {cart.map((entry, index) => {
            return (
              <Item
                id={entry.id}
                key={index}
                title={entry.title}
                description={entry.description}
                specifics={entry.specifics}
                email={entry.sweepstakes?.email}
                total={entry.specifics.total}
                sweepstakes={!!entry.sweepstakes?.entered}
              />
            );
          })}
        </ul>
        <h1 className={css.total}>
          <span>total:</span>
          <span>${price}</span>
        </h1>
        <Link
          style={{ width: "100%", maxWidth: "32rem" }}
          to="/shop/checkout"
          className="button"
        >
          CONTINUE TO CHECKOUT
        </Link>
        <Link
          className="link"
          style={{ maxWidth: "32rem" }}
          to="/sweepstakes/live"
        >
          BACK TO SWEEPSTAKE
        </Link>
      </div>
    );
  } else {
    return (
      <div className={css.main}>
        <h1>there's nothing here...</h1>
        <p>your cart is empty. let's fill it!</p>
        <Link className="link" to={"/sweepstakes/live"}>
          JOIN LIVE SWEEPSTAKE
        </Link>
      </div>
    );
  }
};

export default Cart;
