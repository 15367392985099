import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import css from "./Checkout.module.css";
import { useHttp } from "../../helpers/useHttp";
import CartContext from "../../context/cart";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import Form from "./Form";

const Checkout = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { sendRequest } = useHttp();
  const { cart, order, createOrder } = useContext(CartContext);

  const stripe = loadStripe(
    process.env.NODE_ENV === "production"
      ? "pk_live_51PG1cBE8JDSpBeryp3BIbhNWLcDtWjXU0oUdnwFXJ8D0pzE6SvJkhHhqgGW0Auub5bfRzRsASl7FUZaStIKJvKoT00feXsVsP6"
      : "pk_test_51PG1cBE8JDSpBerypldkR24EqpIqmTUTtiLT32gnvIeu1eKpAuy2OF3UnCcfM4v44ChBPaHTE11fDepFhR7VUL6o006FU8KLOH"
  );

  useEffect(() => {
    async function getOrder() {
      if (!order) {
        try {
          const order = await sendRequest(
            "payment-intent",
            "POST",
            JSON.stringify(cart)
          );
          createOrder(order);
        } catch (error) {
          setError(true);
        }
      }
    }
    getOrder();
    setLoading(false);
  }, [cart, createOrder, order, sendRequest]);

  const options = {
    clientSecret: order?.payment?.secret,
    appearance: {
      theme: "flat",

      variables: {
        colorBackground: "white",
        fontFamily: "Quicksand",
        colorText: "#161616",
        colorDanger: "#f2d2bd",
        gridRowSpacing: "1rem",
        gridColumnSpacing: "1rem",
      },

      rules: {
        ".Input": {
          backgroundColor: "rgba(255, 255, 255, 0.32)",
          color: "white",
          borderRadius: "2rem",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          padding: "0.55rem 0.8rem",
          fontSize: "1rem",
        },
        ".Input--empty": {
          backgroundColor: "white",
        },
        ".Input--invalid": {
          color: "#bc0000",
          backgroundColor: "#ffb8b8",
        },
        ".Label": { color: "#ffffffa3" },
        ".Label--empty": {
          color: "white",
        },
        ".Label--invalid": {
          color: "#f2d2bd",
        },
        ".CheckboxLabel": { color: "#ffffffa3" },
      },
    },
    fonts: [
      {
        family: "Quicksand",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap",
        weight: "700",
      },
    ],
  };

  if (error) {
    return (
      <div className={`${css.main} gradient`}>
        <h1>something went wrong</h1>
        <p>
          we weren't able to check you out. please adjust your cart and try
          again.
        </p>
        <Link to="/shop/cart" className="link">
          BACK TO CART
        </Link>
      </div>
    );
  }

  if (loading || !order || !stripe) {
    return (
      <div className={`${css.main} gradient`}>
        <h1>loading...</h1>
      </div>
    );
  } else if (!cart.length > 1) {
    return (
      <div className={`${css.main} gradient`}>
        <h1>there's nothing here.</h1>
        <p>you don't have any items in your cart.</p>
        <Link to="/sweepstakes/live" className="link">
          START SHOPPING
        </Link>
      </div>
    );
  }

  return (
    <Elements stripe={stripe} options={options}>
      <div className="gradient">
        <ul className={css.list}>
          <h2>order summary</h2>
          {order.items.map((item, index) => (
            <li className={css.item} key={index}>
              <h3 className={css.title}>{item.title}</h3>
              <span className={css.description}>
                {item.specifics.color} {item.specifics.customization}{" "}
                {item.specifics.style}
              </span>
              <span>${item.specifics.total}</span>
            </li>
          ))}
          <li className={css.total}>
            <strong>total:</strong>
            <strong>${order.payment.total}</strong>
          </li>
        </ul>
      </div>
      <div className={css.dark}>
        <Form />
      </div>
      {/* </div> */}
    </Elements>
  );
};

export default Checkout;
