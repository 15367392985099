import React, { useContext, useRef, useState } from "react";

import CartContext from "../../context/cart";

import { Link } from "react-router-dom";
import { useHttp } from "../../helpers/useHttp";

import Input from "../../components/input/Input";
import Radio from "../../components/radio/Radio";
import Intro from "../../components/intro/Intro";
import UL from "../privacy_policy/UL";
import LI from "../../components/LI/LI";
import Pictures from "../../components/pictures/Pictures";
import britain from "../art/pictures/britain.webp";
import apricot from "../art/pictures/apricot.webp";
import squidslippers from "../art/pictures/squidslippers.webp";
import pig from "../art/pictures/pig.webp";
import Accordion from "../../components/_accordion/Accordion";

const LiveSweepstakes = () => {
  const { cart, addToCart } = useContext(CartContext);

  const [loading, setLoading] = useState(false);

  const typeRef = useRef(null);
  const wordRef = useRef(null);

  const [customization, setCustomization] = useState("");
  const [customizationError, setCustomizationError] = useState("");

  const [color, setColor] = useState("");
  const [colorError, setColorError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [style, setStyle] = useState("");
  const [styleError, setStyleError] = useState("");

  const [type, setType] = useState("charm");
  const [typeError, setTypeError] = useState("");

  const { sendRequest } = useHttp();

  const addName = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      validate();

      let savedEntry = await sendRequest(
        "wire-name",
        "POST",
        JSON.stringify({
          specifics: {
            color,
            customization,
            style,
            type,
          },
          sweepstakes: { entered: new Date().getTime(), email },
        })
      );

      addToCart(savedEntry);

      setCustomization("");
      setStyle("");
      setColor("");

      if (wordRef.current) {
        wordRef.current.focus();
      }

      if (typeRef.current) {
        typeRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {}
    setLoading(false);
  };

  function validate() {
    let error = false;

    if (!["name/word", "charm"].includes(type)) {
      setTypeError("choose a valid type (name/word or charm)!");
      error = true;
    }

    switch (type) {
      case "name/word":
        if (!/^[A-Za-z .]+$/.test(customization)) {
          setCustomizationError(
            "enter a valid word or name (letters, spaces, and periods only)!"
          );
          if (typeRef.current) {
            typeRef.current.scrollIntoView({ behavior: "smooth" });
          }
          error = true;
        }
        break;
      case "charm":
        if (!["cactus", "heart", "lightning", "star"].includes(customization)) {
          setCustomizationError(
            "choose a valid charm (cactus, heart, lightning, or star)!"
          );
          error = true;
        }
        break;
      default:
        break;
    }

    if (!["copper", "gold", "rose gold", "silver"].includes(color)) {
      setColorError(
        "choose a valid color (copper, gold, rose gold, or silver)!"
      );
      error = true;
    }

    if (!["earring", "keychain", "necklace", "standalone"].includes(style)) {
      setStyleError(
        "choose a valid style (earring, keychain, necklace, or standalone)!"
      );
      error = true;
    }

    if (!email) {
      setEmailError("enter your email!");
      error = true;
    }

    if (error) {
      throw {
        code: 400,
        name: "WIRE NAME: INVALID INPUT",
      };
    }
  }

  const changeColor = (color) => {
    setColorError("");
    setColor(color);
  };

  const changeCustomization = (e) => {
    setCustomizationError("");
    setCustomization(e.target.value);
  };

  const changeCustomization2 = (shape) => {
    setCustomizationError("");
    setCustomization(shape);
  };

  const changeEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const changeStyle = (style) => {
    setStyleError("");
    setStyle(style);
  };

  const changeType = (type) => {
    setTypeError("");
    setCustomization("");
    setCustomizationError("");
    setType(type);
  };

  let disabled =
    customization?.length === 0 ||
    style?.length === 0 ||
    color?.length === 0 ||
    email?.length === 0;
  return (
    <div className="main">
      <Intro
        primary="WIRE ART"
        secondary="we're LIVE on tiktok making wire names and jewelry! if you want to enter for a chance to win..."
        tertiary="you're in the right place."
      />

      <div className="column p01">
        <h2>HOW IT WORKS</h2>

        <Accordion open title="ENTRY">
          <p className="o64">
            it's free to enter! just fill out the form, choosing your preferred
            type, customization, color, and style! every submission is added to
            the official list LIVE on stream!
          </p>
          <p>
            every entry increases your odds of winning, so don't be afraid to
            enter multiple times!
          </p>
        </Accordion>
        <Accordion open title="SELECTION">
          {" "}
          <p className="o64">
            once i'm done with the submission i'm working on...
          </p>
          <p className="o64">
            ...if there are any outstanding orders, i'll work on the next one!
          </p>
          <p className="o64">
            ...if there are no orders, i'll choose a random winner!
          </p>
        </Accordion>
        <Accordion open title="WINNING">
          <p>
            winners get their submission for FREE! and they get to watch me make
            it live on stream!
          </p>
        </Accordion>
      </div>

      <div className="column p01">
        <h2>ENTER TO WIN</h2>

        <form onSubmit={addName}>
          <Input
            error={emailError}
            label="enter your email"
            onChange={changeEmail}
            type="email"
            value={email}
          />
          <Radio
            error={typeError}
            onChange={changeType}
            options={[
              { display: "charm", value: "charm" },
              { display: "name/word", value: "name/word" },
            ]}
            selected={type}
            title="choose a type"
            ref={typeRef}
          />
          {type === "name/word" && (
            <Input
              autoFocus
              error={customizationError}
              label="enter a name/word"
              onChange={changeCustomization}
              value={customization}
              ref={wordRef}
            />
          )}
          {type === "charm" && (
            <Radio
              error={customizationError}
              onChange={changeCustomization2}
              options={[
                { display: "cactus", value: "cactus" },
                { display: "heart", value: "heart" },
                { display: "lightning", value: "lightning" },
                { display: "star", value: "star" },
              ]}
              selected={customization}
              title="choose a shape"
            />
          )}
          <Radio
            error={styleError}
            onChange={changeStyle}
            options={[
              { display: "earring", value: "earring" },
              { display: "keychain", value: "keychain" },
              { display: "necklace", value: "necklace" },
              { display: "standalone", value: "standalone" },
            ]}
            selected={style}
            title="choose a style"
          />
          <Radio
            error={colorError}
            onChange={changeColor}
            options={[
              { display: "copper", value: "copper" },
              { display: "gold", value: "gold" },
              { display: "rose gold", value: "rose gold" },
              { display: "silver", value: "silver" },
            ]}
            selected={color}
            title="choose a color"
          />

          <button
            className={disabled ? "disabled" : null}
            type="submit"
            style={{ marginTop: 0 }}
            disabled={disabled}
          >
            {loading ? "loading..." : "ENTER SWEEPSTAKE"}
          </button>
        </form>

        {cart.length > 0 && (
          <div className={`column card`}>
            <h3 style={{ textAlign: "center" }}>IF YOU CHOOSE TO BUY</h3>
            <UL style={{ maxWidth: "40rem" }}>
              <LI>
                you'll skip the line! orders take priority over submissions, so
                i'll make yours next.
              </LI>
              <LI>you'll get to keep it forever!</LI>
              <LI>
                i'll take my time! i sometimes rush, but i'll make sure yours is
                perfect.
              </LI>
            </UL>
            <Link className="button" to="/shop/cart">
              BUY NOW
            </Link>
          </div>
        )}
      </div>

      <div className="column p01">
        <h2>MORE OF MY ART</h2>
        <Pictures
          key={1}
          pictures={[
            {
              src: britain,
              alt: "a husband and wife looking at each other",
              description:
                "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
            },
            {
              src: apricot,
              alt: "apricot",
              description: "a hyper-realistic apricot i drew using procreate.",
            },
            {
              src: squidslippers,
              alt: "squidslippers",
              description: "my squidslippers logo! i love this little guy!",
            },
            {
              src: pig,
              alt: "pig",
              description: "an acrylic painting i made as a commission.",
            },
          ]}
        />
        <Link className="link" to={"/art"}>
          EXPLORE MY ART
        </Link>
      </div>
    </div>
  );
};

export default LiveSweepstakes;
