import React from "react";
import css from "./Dropdown.module.css";

const Dropdown = ({ error, label, name, onChange, options, selected }) => {
  const legendClass = `${css.legend} ${
    selected === "—" || !!selected ? undefined : css.legendBlank
  } ${error ? css.legendError : undefined}`;

  return (
    <div className={css.main}>
      <label className={legendClass} htmlFor={name}>
        {error ? error : label}
      </label>
      <select
        className={css.select}
        name={name}
        value={selected}
        onChange={(e) => onChange(e.target.value)}
      >
        {options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
