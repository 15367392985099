import React from "react";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

import bears from "./pictures/bears.webp";
import birds from "./pictures/birds.jpg";
import beach from "./pictures/beach.webp";
import corn from "./pictures/corn.webp";
import clouds1 from "./pictures/clouds1.jpg";
import doctor from "./pictures/doctor.jpg";
import dolf from "./pictures/dolf.jpg";
import donuts from "./pictures/donuts.jpg";
import eevee from "./pictures/eevee.webp";
import embers from "./pictures/embers.webp";
import emma from "./pictures/emma.jpg";
import feet from "./pictures/feet.webp";
import flower from "./pictures/flower.webp";
import flowers from "./pictures/flowers.jpg";
import grateful from "./pictures/grateful.jpg";
import harry from "./pictures/harry.jpg";
import jellyfish from "./pictures/jellyfish.webp";
import jonah from "./pictures/jonah.webp";
import maple from "./pictures/maple.jpg";
import max from "./pictures/max.webp";
import me from "./pictures/me.webp";
import meat from "./pictures/meat.webp";
import nightstand from "./pictures/nightstand.webp";
import parents from "./pictures/parents.jpg";
import raja from "./pictures/raja.jpg";
import sleep from "./pictures/sleep.jpg";
import streamers from "./pictures/streamers.webp";
import swag from "./pictures/swag.jpg";
import wallflowers from "./pictures/wallflowers.jpg";
import zen from "./pictures/zen.jpg";

const Photography = () => {
  const pictures = [
    {
      src: corn,
      alt: "anika standing in front of corn.",
      description: "look how cute my wife is!",
    },
    {
      src: sleep,
      alt: "anika sleeping.",
      description: "she really just looks like that when she's sleeping. wow.",
    },
    {
      src: doctor,
      alt: "anika on a doctor table",
      description:
        "proof that anika looks good at all times—even in an emergency.",
    },
    {
      src: beach,
      alt: "anika at the beach",
      description: "if this is a dream... don't wake me up.",
    },
    {
      src: clouds1,
      alt: "clouds",
      description: "(the sky is the artist here; i just took the picture.)",
    },
    {
      src: flowers,
      alt: "close-up shot of flowers",
      description: "(i didn't even edit this one!)",
    },
    {
      src: wallflowers,
      alt: "flowers in vases hanging from a white wall.",
      description: "the perks of being a what now?",
    },
    {
      src: streamers,
      alt: "a close-up of multicolored streamers that were dangling from the ceiling and blowing in the wind.",
      description: "party time!",
    },
    {
      src: max,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: eevee,
      alt: "a dog lying on a couch",
      description: "it's eevee baby!",
    },
    {
      src: jonah,
      alt: "a brother and sister hugging",
      description:
        "taken mere moments before emma left on her mission to rome, italy.",
    },
    {
      src: maple,
      alt: "a dog sitting on the sidewalk",
      description: "it's maple baby!",
    },
    {
      src: flower,
      alt: "a bright orange flower on a black background",
      description:
        "this gorgeous flower is planted in front of our favorite coffee shop.",
    },
    {
      src: raja,
      alt: "red, white, and blue betta fish",
      description:
        "anika and i love betta fish. everyone, this is raja. raja, everyone.",
    },
    {
      src: embers,
      alt: "embers",
      description: "i could stare at embers for hours...",
    },
    {
      src: jellyfish,
      alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
      description: "anika's little slice of hell. (she hates jellyfish.)",
    },
    {
      src: bears,
      alt: "three young children in bear costumes",
      description: "these kids are so cute and an absolute blast to be around.",
    },
    {
      src: emma,
      alt: "emma",
      description: "a candid pic i got of emma on a random sunday afternoon.",
    },
    {
      src: dolf,
      alt: "two people celebrating",
      description:
        "our dad is good at disc golf... the rest of us are moral support.",
    },
    {
      src: parents,
      alt: "parents",
      description: "my mom and dad!",
    },

    {
      src: meat,
      alt: "diced steak in a pan",
      description:
        "steak: the breakfast of champion. (it's me; i'm the champion.)",
    },
    {
      src: harry,
      alt: "harry mack in concert",
      description:
        "this was one of the best concerts—and most impressive things—i've ever witnessed.",
    },
    {
      src: feet,
      alt: "feet prints in sand",
      description:
        "getting a picture like this is NOT easy, i'll tell you that much.",
    },
    {
      src: birds,
      alt: "two shadows raising their middle fingers",
      description: "get wrecked!",
    },
    {
      src: donuts,
      alt: "close-up shot of colorful donuts",
      description: "our favorite donut shop sells the cutest little treats.",
    },
    {
      src: nightstand,
      alt: "black nightstand with a purple orchid, gold tabletop lamp, and black zen garden with white sand with rainbow-colored stones buried wtihin. To the right of that is a white bed sheet on top of a bed.",
      description: "my little slice of heaven.",
    },
    {
      src: zen,
      alt: "a zen garden.",
      description: "the zen garden max made me. it's awesome.",
    },
    {
      src: me,
      alt: "a close-up shot of my face. (i'm not describing my face to you; sorry.)",
      description:
        "a self-portrait i took. i think i really nailed the 'mysterious guy lurking in the corner' vibe i was going for.",
    },
    {
      src: grateful,
      alt: "me standing in front of a hearth.",
      description: "i staged and took this picture a looooong time ago.",
    },
    {
      src: swag,
      alt: "me wearing sunglasses with 7 $1 bills stuffed between then and my face. it's a mirror selfie, with my hand and phone visible. i'm wearing a shirt that says 'CINNAMON TOAST CRUNCH', which is awesome.",
      description: "dress for the job you want!!",
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="SAY CHEEEEESE"
        secondary="i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it."
      />
      <div className="column p01">
        <h2>MY PICTURES</h2>
        <p className="o64 ctext">
          i took and edited all of these pictures myself!
        </p>
        <p className="o64 ctext">
          my goal with photography is simple: to let you experience the world
          through my lens—not just how the moment <i>looked</i> from my
          perspective, but also how it <i>felt</i>. that's why i tend tend to
          over-exaggerate properties like colors and contrast when i edit,
          resulting in vibrant and vivid photos that jump off the screen.
        </p>
        <strong>try clicking on one!</strong>
        <Pictures pictures={pictures} />
        {/* <h2>TALENTED PHOTOGRAPHERS</h2>
        <p className="o64 ctext">
          i've met and been exposed to a lot of talented photographers, many of
          whom have taken the time to master the craft. but there are a select
          few whose style and skill steal my attention. i've spent a solid
          amount of time admiring and learning from their work.
        </p>
        <p className="o64 ctext">
          and they do it professionally, so you can get some of their work for
          yourself! go check them out and get yourself some amazing pictures!
        </p>
        <h3>JESSICA DAVIES PHOTOGRAPHY</h3>
        <Pictures
          pictures={[
            {
              src: "https://scontent.fslc3-1.fna.fbcdn.net/v/t1.6435-9/53362998_2008760435917755_4592355740722659328_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=0327a3&_nc_ohc=6IdGF7AjLdwQ7kNvgHZGOly&_nc_ht=scontent.fslc3-1.fna&_nc_gid=A51gGNsJ4VC6iApLkC3-qK3&oh=00_AYA_uj07BOllwsZmORioxS2A2s2ZKEiWaF2YvGKnOOtfUg&oe=671C68AB",
              alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
              description: "inspired by emma.",
            },
            {
              src: "https://scontent.fslc3-1.fna.fbcdn.net/v/t1.6435-9/46091625_1836130339847433_4246474611262226432_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=0327a3&_nc_ohc=11ZljZc4MdwQ7kNvgFrNbfR&_nc_ht=scontent.fslc3-1.fna&_nc_gid=AQtkAOk2jbNMeaVCvvbNOJB&oh=00_AYAbhG2b9NsSJiIGkJZKr11887NdTAtfvyTH0YVX71ngJQ&oe=671C4F86",
              alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
              description: "inspired by emma.",
            },
            {
              src: "https://scontent.fslc3-2.fna.fbcdn.net/v/t1.6435-9/41205226_1753154731478328_3533708617939156992_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=13d280&_nc_ohc=y55AMYQ_EU8Q7kNvgG7y3V1&_nc_ht=scontent.fslc3-2.fna&_nc_gid=AYVicAjQu7a9-GxTzbtB4wB&oh=00_AYBss1qIn6BuaOieuT-xYpfbmO4QWDXNcwpppvbAPzlROA&oe=671C6830",
              alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
              description: "inspired by emma.",
            },
            {
              src: "https://scontent.fslc3-1.fna.fbcdn.net/v/t1.6435-9/40517295_1744725845654550_4203696522245701632_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=0327a3&_nc_ohc=W5IUyvV11pUQ7kNvgF6mgzE&_nc_ht=scontent.fslc3-1.fna&_nc_gid=AUjE7A-tUQqGTEdyvquB78t&oh=00_AYBqxNe4RGD9e5QIMCrtFNtBRUbv0XDYHRw23gzW0Kbb2A&oe=671C5B76",
              alt: "a bunch of white jellyfish in stark contrast against a deep blue background.",
              description: "inspired by emma.",
            },
          ]}
        />
        <a className="link">WEBSITE</a>
        <a className="link">INSTAGRAM</a> */}
      </div>
    </div>
  );
};

export default Photography;
