import React, { createContext, useCallback, useState } from "react";

const CartContext = createContext({
  addToCart: () => {},
  calculateTotal: () => {},
  cart: [],
  clearCart: () => {},
  createOrder: () => {},
  order: {},
  prices: {},
  removeFromCart: () => {},
});

export const CartContextProvider = (props) => {
  const previousCart = JSON.parse(sessionStorage.getItem("cart")) || [];
  const previousOrder = JSON.parse(sessionStorage.getItem("order")) || null;

  const [cart, setCart] = useState(previousCart);
  const [order, setOrder] = useState(previousOrder);

  const prices = {
    customization: {
      cactus: 4,
      heart: 2,
      lightning: 3,
      star: 4,
    },
    style: {
      earring: 1,
      keychain: 1,
      necklace: 2,
      standalone: 0,
    },
  };

  const addToCart = useCallback(
    (item) => {
      setCart((cart) => {
        let newCart = [...cart, item];
        sessionStorage.setItem("cart", JSON.stringify(newCart));
        return newCart;
      });
      sessionStorage.removeItem("order");
      setOrder(null);
    },
    [setCart]
  );

  const calculateTotal = useCallback(
    (item) => {
      let total = 0;

      switch (item.title) {
        case "custom wire art":
          let itemTotal = 0;

          switch (item.type) {
            case "charm":
              let charmCost = prices.customization[item.customization] || 0;
              itemTotal = itemTotal + charmCost;
              break;
            case "name/word":
              let customizationCost = item.customization.length || 0;
              itemTotal = itemTotal + customizationCost;
              break;
            default:
              break;
          }

          let styleCost = prices.style[item.style] || 0;
          itemTotal = itemTotal + styleCost;

          item.total = itemTotal;
          total = total + itemTotal;
          break;
        default:
          break;
      }
      return total;
    },
    [prices.customization, prices.style]
  );

  const clearCart = useCallback(() => {
    setCart([]);
    setOrder(null);
    sessionStorage.removeItem("cart");
    sessionStorage.removeItem("order");
  }, []);

  const createOrder = useCallback((order) => {
    setOrder(order);
    sessionStorage.setItem("order", JSON.stringify(order));
  }, []);

  const removeFromCart = useCallback(
    (id) => {
      setCart((cart) => {
        let newCart = cart.filter((item) => item.id !== id);
        sessionStorage.setItem("cart", JSON.stringify(newCart));
        return newCart;
      });
      sessionStorage.removeItem("order");
      setOrder(null);
    },
    [setCart]
  );

  const context = {
    addToCart,
    calculateTotal,
    cart,
    clearCart,
    createOrder,
    order,
    prices,
    removeFromCart,
  };

  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
