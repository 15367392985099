import React from "react";
import { Link, useLocation } from "react-router-dom";
import css from "./Header.module.css";

const Header = () => {
  const location = useLocation();

  let link = (
    <Link to="/" className={css.home}>
      {"< home"}
    </Link>
  );

  if (["/", "/admin/live/view"].includes(location.pathname)) link = null;

  return <div className={css.header}>{link}</div>;
};

export default Header;
