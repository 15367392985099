import React, { useEffect, useState } from "react";
import css from "./Carousel.module.css";

const Carousel = (props) => {
  const { pictures } = props;
  const [index, setIndex] = useState(0);

  const slideshow = pictures?.map((picture, i) => (
    <img
      src={picture.src}
      alt={picture.alt}
      className={i === index ? css.me_visible : css.me_invisible}
      key={i}
    />
  ));

  useEffect(() => {
    setTimeout(
      () =>
        setIndex((current) => {
          if (current === pictures?.length - 1) return 0;
          else return (current += 1);
        }),
      3000
    );
  }, [index, pictures?.length]);

  return <div className={css.carousel}>{slideshow}</div>;
};

export default Carousel;
