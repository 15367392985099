import React from "react";
import css from "./LI.module.css";
import check from "./check.png";

const LI = ({ children }) => {
  return (
    <li className={css.li}>
      <img src={check} alt="check" className={css.img} />
      {children}
    </li>
  );
};

export default LI;
