import { useCallback, useContext, useRef, useEffect } from "react";
import NotificationsContext from "../context/notifications";
import IAMContext from "../context/iam";

export const useHttp = () => {
  const activeHttpRequests = useRef([]);
  const { addNotification } = useContext(NotificationsContext);
  const { token } = useContext(IAMContext);

  const sendRequest = useCallback(
    async (endpoint, method = "GET", body = null, iam) => {
      const httpAbortController = new AbortController();
      activeHttpRequests.current.push(httpAbortController);

      let baseURL;

      process.env.NODE_ENV === "production"
        ? (baseURL =
            "https://backend-dot-squidslippers.wm.r.appspot.com/api/v1/")
        : (baseURL = "http://localhost:8080/api/v1/");

      try {
        const response = await fetch(baseURL + endpoint, {
          method,
          body,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          signal: httpAbortController.signal,
        });

        const responseData = await response.json();

        if (!responseData.payload) {
          throw responseData;
        }
        const { payload, message } = responseData;

        addNotification(message);
        return payload;
      } catch (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        const { payload, message } = error;
        if (message) addNotification(message);
        if (payload) {
          throw payload;
        } else {
          throw {};
        }
      }
    },
    [addNotification]
  );

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((AbortController) =>
        AbortController.abort()
      );
    };
  }, []);

  return { sendRequest };
};
