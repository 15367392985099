import React from "react";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/_accordion/Accordion";
import megan from "./pictures/megan.jpg";
import oldest from "./pictures/oldest.jpg";
import purse from "./pictures/purse.jpg";
import Pictures from "../../components/pictures/Pictures";

const SocialMedia = () => {
  return (
    <div className="main">
      <Intro
        primary="SOCIAL MEDIA"
        secondary="buzzfeed wrote an article about me. i somehow have 150k+ followers on tiktok. f***jerry paid me $50. james the mormon owes me a favor. if you want to see what i'm up to online..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>MY SOCIALS</h2>
        <Accordion title="LINKEDIN">
          <p className="o64">
            i can't stand linkedin. and i can't stand the fact that i need it so
            much and use it so often. i dream of being so successful that i can
            only use linkedin ironically. one day...
          </p>
          <p className="o64">
            but i've gone viral a few times, and i've successfully used linkedin
            to land more than one job, so i'll stop complaining!
          </p>
          <a
            className="button"
            href="https://www.linkedin.com/in/jacob-moreno/"
            target="_blank"
            rel="noreferrer noopener"
          >
            CHECK IT OUT
          </a>
        </Accordion>
        <Accordion title="YOUTUBE">
          <p className="o64">
            i looooove asmr. i've been listening to it for years, and i recently
            tried my hand at making some.
          </p>
          <p className="o64">
            my first attempt was okay. i have since learned a lot, and i have a
            few videos in the works that i'm really excited about! i'm exploring
            some concepts that i've never seen before.
          </p>
          <a
            className="button"
            href="https://www.youtube.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
          >
            CHECK IT OUT
          </a>
        </Accordion>
        <Accordion title="TIKTOK">
          <p className="o64">
            i've gone viral enough times to gain a pretty decent following. but
            the bummer is each time i've gone viral, it's been for WILDLY
            different things, so my following is all over the place.
            consequently, every time i post (no matter what i post) i lose
            followers—until i randomly go viral again.
          </p>
          <a
            className="button"
            href="https://www.tiktok.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
          >
            tiktok
          </a>
        </Accordion>
        <Accordion title="INSTAGRAM">
          <p className="o64">
            i'm actually moving away from instagram as a platform because i
            don't like it. i recently downloaded all of my information from
            instagram, posted it here instead, and am planning to delete my
            account soon. rip.
          </p>
        </Accordion>
        <Accordion title="X">
          <p className="o64">
            i used to be a pretty big deal on twitter! i've since deleted my
            account, and my famous "@squidslippers" handle has since been taken
            over by some random person. rip.
          </p>
        </Accordion>
        <h2>CONTENT</h2>
        <Pictures
          pictures={[
            { src: megan, alt: "d" },
            {
              src: purse,
              alt: "girls be like 'can you get my lip gloss from my purse? just reach in & head left, take a right at the wallet then turn left til you pass 3 nutrigrain bars & take your next right then head straight & it should be there. if you've hit the 2007 target recepts you've gone too far'",
            },
            {
              src: oldest,
              alt: "girls be like 'can you get my lip gloss from my purse? just reach in & head left, take a right at the wallet then turn left til you pass 3 nutrigrain bars & take your next right then head straight & it should be there. if you've hit the 2007 target recepts you've gone too far'",
            },
          ]}
        />
        <h2>FEATURES</h2>
        <a
          className="link"
          href="https://www.buzzfeednews.com/article/remysmidt/aristotle-comes-through"
          target="_blank"
          rel="noreferrer noopener"
        >
          BUZZFEED
        </a>
        <a
          className="link"
          href="https://www.yahoo.com/news/professor-helps-student-ask-crush-102620264.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          YAHOO
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/BsMK0zMAVqR/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @BUZZFEED
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/CN-EPx2HJUG/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
        >
          @MENSHUMOR
        </a>
        <a
          className="link"
          href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @THEDAD
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/B5JPze4n-db/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @SHIP
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/Br9I8g3gLDJ/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @NO.F*CKSGIIVEN
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/C8WwgOcOMNT/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @FEAROFGOINGOUT
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/Bi5d5jzF3S7/?img_index=1"
          target="_blank"
          rel="noreferrer noopener"
        >
          @SELFHELPTHREADS
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/BcD-8PlHVm7/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @KALESALADQUOTES
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/C7J4Gp2L-U6/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @MOMMYWINETIME
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/CdQ5v5nutzp/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @ASAFEPLACEINSIDEYOURHEAD
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/CWUjj_MLpsk/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @THEBASICGAYDRUNK
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/CQ_jas3B0Oy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @THETINDERBLOG
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/COn_Xg5jMyF/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @IMTHIRTYAF
        </a>
        <a
          className="link"
          href="https://www.instagram.com/p/COokK7-ry_f/"
          target="_blank"
          rel="noreferrer noopener"
        >
          @GIRLSTHINKIMFUNNY
        </a>
        <a
          className="link"
          href="https://www.instagram.com/thedad/p/CyM3nY3uM-u/"
          target="_blank"
          rel="noreferrer noopener"
        >
          MY BIGGEST HATER
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
