import React, { useEffect, useState } from "react";
import { socket } from "../../../helpers/socket";
import css from "./Live.module.css";
import ss from "./squidslippers.png";

const View = () => {
  const previousEntries = JSON.parse(sessionStorage.getItem("entries")) || [];
  const previousWinner = JSON.parse(sessionStorage.getItem("winner")) || null;

  const [entries, setEntries] = useState(previousEntries);
  const [winner, setWinner] = useState(previousWinner);

  const [retryCount, setRetryCount] = useState(0);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const connect = () => {
      socket.on("connect", () => {
        setConnected(true);

        console.log("connected!");
      });

      socket.on("disconnect", () => {
        setConnected(false);

        console.log("disconnected.");
      });

      socket.on("connect_error", (err) => {
        console.error(`FAIL: ${err?.message}`);

        setRetryCount((oldCount) => {
          console.log(`retryCount: ${oldCount + 1}`);

          return oldCount + 1;
        });

        if (socket.active) {
          console.log("automatically retrying to connect.");
        } else {
          console.log("permanent failure. disconnecting...");
          socket.disconnect();
        }
      });

      socket.on("entry", (entry) => {
        setEntries((oldList) => {
          let newEntries = [entry, ...oldList];
          sessionStorage.setItem("entries", JSON.stringify(newEntries));
          return newEntries;
        });
      });

      socket.on("winner", (winner) => {
        setWinner(winner);
        sessionStorage.setItem("winner", JSON.stringify(winner));
      });

      socket.connect();
    };

    if (retryCount < 5) {
      console.log("retrying to connect.");
      connect();
    } else {
    }

    return () => {
      socket?.disconnect();
    };
  }, [retryCount]);

  function deleteEntry(id) {
    setEntries((old) => {
      const newArray = [...old];
      const index = old.findIndex((entry) => entry.id === id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
      sessionStorage.setItem("entries", JSON.stringify(newArray));
      return newArray;
    });
  }

  return (
    <div className="main">
      <div className="gradient" style={{ padding: "2rem 1rem" }}>
        <img src={ss} alt="squidslippers.com" className={css.img} />
        <h1 style={{ marginTop: "-2rem", fontSize: "2.5rem" }}>
          LIVE SWEEPSTAKES GIVEAWAY!
        </h1>
      </div>
      <div className="column p01" style={{ marginTop: "-2rem" }}>
        <h2>WINNER</h2>
        <div className={css.winner}>
          {winner && (
            <div className={css[winner.specifics.color.replace(/\s/g, "")]}>
              <strong style={{ fontSize: "5rem", marginTop: "-2rem" }}>
                {winner.specifics.customization}
              </strong>
              <p style={{ textAlign: "center", fontSize: "1.5rem" }}>
                ( {winner.specifics.color} {winner.specifics.type}{" "}
                {winner.specifics.style} )
              </p>
            </div>
          )}
        </div>
        <h2>ENTRIES</h2>
        <ul className={css.list} style={{ height: "30rem" }}>
          {entries.length > 0 &&
            entries.map((entry) => {
              return (
                <li
                  key={entry.id}
                  className={`${css.entry} ${
                    entry?.ordered ? css.ordered : undefined
                  } ${css[entry.specifics.color.replace(/\s/g, "")]}`}
                >
                  <button
                    onClick={() => {
                      deleteEntry(entry.id);
                    }}
                    className={css.delete}
                  >
                    X
                  </button>
                  <div className={css.row}>
                    <strong className={css.customization}>
                      {entry.specifics.customization}
                    </strong>
                    <span className={css.specifics}>
                      {entry.specifics.type} • {entry.specifics.style} •{" "}
                      {entry.specifics.color}
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default View;
