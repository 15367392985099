import React from "react";
import Intro from "../../../components/intro/Intro";
import Pictures from "../../../components/pictures/Pictures";

import descending from "./descending.webp";
import falling from "./falling.webp";
import line from "./line.webp";
import name from "./name.webp";
import Accordion from "../../../components/_accordion/Accordion";

const ColoredPaincils = () => {
  return (
    <div className="main">
      <Intro
        primary="COLORED PAINCILS"
        secondary="if you want a self-defense device that can go with you everywhere, does some serious damage, and looks good doing it..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>FUN FOR EVERYBODY</h2>
        <i className="o64" style={{ marginTop: "-1.5rem" }}>
          (ALMOST)
        </i>

        <Pictures
          pictures={[
            {
              src: line,
              alt: "colored pencils falling down a white background.",
            },
            {
              src: descending,
              alt: "giant colored pencils in a line. they're side-by-side, directly adjacent, with the smallest on the left and the largest on the right. from left to right, they're in this order: black, purple, blue, green, yellow, orange, red, pink, white",
            },
            {
              src: falling,
              alt: "colored pencils falling down a white background.",
            },
            {
              src: name,
              alt: "colored pencils falling down a white background.",
            },
          ]}
        />

        <p className="o64">
          the colored paincil is a branch of strong wood (oak) that i carve and
          paint to look like a larger-than-life colored pencil. it looks fun and
          playful, but this self-defense device packs quite a punch! check out
          some of the things that make it special:
        </p>
        <Accordion title="PROTECT YOUR FIST">
          <p className="o64">
            punching can be dangerous. even with good form, it can be easy to
            hurt—and even break—your hand. the colored paincil reinforces your
            fist, reducing the risk of injury!
          </p>
        </Accordion>
        <Accordion title="TAKE IT EVERYWHERE">
          <p className="o64">
            it's small enough to fit in your pocket, purse, waistpack, duffel
            bag, backpack, glove box, or anywhere else. and because it's made
            out of wood (and looks so cute), it makes it past security too, so
            you really can take it anywhere! (we've never been stopped with
            one!)
          </p>
        </Accordion>
        <Accordion title="STRENGTHEN YOUR STRIKES">
          <p className="o64">
            the extra reinforcement the colored paincil provides actually makes
            your punches stronger, too. and striking with the tip does serious
            damage! (seriously, use it responsibly!)
          </p>
        </Accordion>
        <Accordion title="LOOK ADORABLE">
          <p className="o64">
            it really looks like a colored pencil! and it comes in a variety of
            super cute colors!
          </p>
        </Accordion>
        <h2>COMING SOON</h2>
        <p className="o64 ctext">
          the colored paincil isn't quite yet ready for order, but it will be in
          the coming weeks!
        </p>
      </div>
    </div>
  );
};

export default ColoredPaincils;
