import React from "react";
import css from "./PrivacyPolicy.module.css";
import UL from "./UL";
import OL from "./OL";
import LI from "../../components/LI/LI";

const PrivacyPolicy = () => {
  return (
    <div className={css.main}>
      <div className="gradient" style={{ gap: "1rem", padding: "1rem" }}>
        <h1>PRIVACY POLICY</h1>
        <strong style={{ marginTop: "-1rem" }}>August 20, 2024</strong>
        <p className="o64">
          This privacy notice for SquidSlippers, LLC ("<strong>we</strong>", "
          <strong>us</strong>," or "<strong>our</strong>"), describes how and
          why we might collect, store, use, and/or share ("
          <strong>process</strong>") your information when you use our services
          ("<strong>Services</strong>
          "), such as when you...
        </p>
        <UL>
          <LI>...visit our website at www.squidslippers.com.</LI>
          <LI>
            ...engage with us in other related ways, including any LIVE streams,
            promotional sales, or marketing events.
          </LI>
        </UL>
        <h2>TABLE OF CONTENTS</h2>
        <OL>
          <LI>THE INFORMATION WE PROCESS</LI>
          <LI>HOW WE PROTECT YOUR INFORMATION</LI>
          <LI>HOW WE PROCESS YOUR INFORMATION</LI>
          <LI>HOW LONG WE KEEP YOUR INFORMATION</LI>
          <LI>WHEN AND WITH WHOM WE SHARE YOUR INFORMATION</LI>
          <LI>OUR AFFILIATION WITH THIRD-PARTY WEBSITES</LI>
          <LI>UPDATES TO THIS POLICY</LI>
          <LI>CONTACTING US ABOUT THIS POLICY</LI>
        </OL>
      </div>
      <div className={css.container}>
        <h2>AGREEMENT</h2>
        <h3>1. THE INFORMATION WE PROCESS</h3>
        <h4>INFORMATION PROVIDED BY YOU</h4>
        <p className="o64">
          We collect information that you voluntarily provide to us when you
          express an interest in obtaining information about us or our products
          and Serices, when you participate in activities with regards to our
          Services, or otherwise when you contact us.
        </p>
        <strong className={css.strong}>Personal Information</strong>
        <p className="o64">
          The personal information that we collect depends on the context of
          your interactions with us and our Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include...
        </p>
        <UL>
          <LI>...your name.</LI>
          <LI>...your phone number.</LI>
          <LI>...your email address.</LI>
          <LI>...your mailing address.</LI>
        </UL>
        <strong className={css.strong}>Sensitive Information</strong>
        <p className="o64">
          When necessary (e.g. when you make a purchase), with your consent and
          when permitted by law, we process sensitive information that may
          include...
        </p>
        <UL>
          <LI>...the last 4 digits of your payment card number.</LI>
        </UL>
        <strong className={css.strong}>Payment Information</strong>
        <p className="o64">
          We do not log, store, or track payment information, including card
          numbers, expiration dates, CVV codes, billing addresses, etc. Instead,
          we rely on our trusted partner, Stripe, to securely process all
          financial transactions. Stripe adheres to strict security standards
          and processes payments securely.
        </p>
        <h4>INFORMATION AUTOMATICALLY COLLECTED</h4>
        <p className="o64">
          We automatically collect some information when you visit, use, or
          navigate our Services. This does not include personal information but
          may include device and usage information.
        </p>
        <strong className={css.strong}>Device and Usage Information</strong>
        <p className="o64">
          Device and usage informatin is service-related, diagnostic, usage, and
          performance information our servers automatically collect when you
          access or use our Services and which we record in log files. Depending
          on how you interact with us, this information may include...
        </p>
        <UL>
          <LI>...your ip address.</LI>
        </UL>
        <strong className={css.strong}>
          Marketing and Analytical Information
        </strong>
        <p className="o64">
          We use Google Analytics to collect important information about our
          website's usage and performance. This information includes device
          type, operating system, browser, IP address, and pages visited. Google
          Analytics handles this data anonymously and securely.
        </p>
        <h3>2. HOW WE PROTECT YOUR INFORMATION</h3>
        <p className="o64">
          We employ exhaustive security measures to protect your personal
          information from unauthorized access, alteration, and deletion. The
          security measures we take to protect your information include...
        </p>
        <UL>
          <LI>
            ...employing strong encryption algorithms to protect data in transit
            and in storage.
          </LI>
          <LI>...conducting regular security audits.</LI>
          <LI>...enforcing role-based access to sensitive data.</LI>
          <LI>
            ...adhering to the Open Web Application Security Project's top 10
            vulnerabilities to address common security risks.
          </LI>
          <LI>
            ...complying with the General Data Protection Regulation to protect
            EU citizens' personal data.
          </LI>
        </UL>
        <p className="o64">
          No technology can be guaranteed to be 100% secure, and there is always
          a risk of unauthorized access. Therefore, we cannot promize or
          guarantee that hackers, cybercriminals, or other unauthorized third
          parties will not be able to defeat our security and improperly access,
          alter, or delete your information. Any and all transmission of
          information (including personal information, sensitive information,
          and payment information) to and from our Services is done at your own
          risk.
        </p>
        <h3>3. HOW WE PROCESS YOUR INFORMATION</h3>
        <p className="o64">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including...
        </p>
        <UL>
          <LI>
            ...to offer customer support and otherwise communicate with
            customers.
          </LI>
          <LI>...to deliver and facilitate delivery of our Services.</LI>
          <LI>
            ...to fulfill and manage orders, payments, returns, and exchanges.
          </LI>
          <LI>
            ...to send marketing and promotional communications in accordance
            with your marketing preferences.
          </LI>
          <LI>
            ...to determine the effectiveness of marketing and promotional
            campaigns.
          </LI>
          <LI>...to analyze and optimize usage and performance.</LI>
        </UL>
        <h3>4. HOW LONG WE KEEP YOUR INFORMATION</h3>
        <p className="o64">
          We only keep your personal information for as long as is necessary to
          fulfill the purpose for which the information was required, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).
        </p>
        <p className="o64">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize your
          information.
        </p>
        <h3>5. WHEN AND WITH WHOM WE SHARE YOUR INFORMATION</h3>
        <h4>TRUSTED THIRD PARTIES</h4>
        <p className="o64">
          We have a short list of third-party service providers whom we trust to
          help us deliver our Services. In order for them to perform services on
          our behalf, they require access to the information we process.
        </p>
        <strong className={css.strong}>Google Analytics</strong>
        <p className="o64">
          We rely on Google Analytics to collect information about our website's
          usage and performance. Whenever you interact with our our site,
          including visiting, navigating to a different page, scrolling,
          clicking or pressing buttons, etc., your information may be shared
          with Google Analytics.
        </p>
        <strong className={css.strong}>Google Cloud Platform</strong>
        <p className="o64">
          We rely on Google Cloud Platform to host our website, database, and
          other digital assets. Whenever you willingly provide us with
          information (including personal and sensitive information—but not
          payment information), that information is shared with Google Cloud
          Platform.
        </p>
        <a
          className="link"
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: "calc(100vw - 2rem)", maxWidth: "46rem" }}
        >
          VIEW GOOGLE'S PRIVACY POLICY
        </a>
        <strong className={css.strong}>Stripe</strong>
        <p className="o64">
          We rely on Stripe to securely process payments. Whenever you purchase
          an item from our website, your information (including personal
          information, sensitive information, and payment information) is shared
          with Stripe.
        </p>
        <a
          className="link"
          href="https://www.stripe.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: "calc(100vw - 2rem)", maxWidth: "46rem" }}
        >
          VIEW STRIPE'S PRIVACY POLICY
        </a>
        <h4>BUSINESS TRANSFERS</h4>
        <p className="o64">
          We may share or transfer your information in connection with, or
          during negotiations of, any merger, sale of company assets, financing,
          or acquisition of all or a portion of our business to another company.
        </p>
        <h3>6. OUR AFFILIATION WITH THIRD-PARTY WEBSITES</h3>
        <p className="o64">
          Our Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          who are not affiliated with us and who may link to other websites,
          services, or applications. The inclusion of a link to any third-party
          website, service, or application does not imply an endorsement by us
          or them.
        </p>
        <p className="o64">
          Any data collected by third parties is not covered by this privacy
          policy. We cannot guarantee the safety and privacy of data you provide
          to any third parties, and we do not make any guarantee regarding any
          such third parties, and we will not be liable for any loss or damage
          caused by the use of such third-party websites, services, or
          applications.
        </p>
        <h3>7. UPDATES TO THIS POLICY</h3>
        <p className="o64">
          We may update this privacy notice. The updated version will be
          indicated by an updated date at the top of this privacy notice. If we
          make material changes to this privacy notice, we may notify you either
          by prominently posting a notice of such changes or by directly sending
          you a notification when appropriate. We encourage you to review this
          privacy notice frequently to be informed of how we are protecting your
          information.
        </p>
        <h3>8. CONTACTING US ABOUT THIS POLICY</h3>
        <p className="o64">
          If you have questions or comments about this notice, please email us
          at <a href="mailto:contact@mantle-xm.com">contact@mantle-xm.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
