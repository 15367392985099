import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Input from "../../components/input/Input";

import { useHttp } from "../../helpers/useHttp";
import Item from "../../components/cart/Item";
import Dropdown from "../../components/dropdown/Dropdown";

const unitedStates = [
  "—",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const Winner = () => {
  const [winner, setWinner] = useState();
  const [winnerError, setWinnerError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [line1, setLine1] = useState("");
  const [line1Error, setLine1Error] = useState("");
  const [line2, setLine2] = useState("");
  const [line2Error, setLine2Error] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const location = useLocation();
  const { sendRequest } = useHttp();

  const getUser = async () => {
    setLoading(true);
    try {
      let path = location.pathname.split("/");
      let id = path[path.length - 1];
      if (id) {
        let winner = await sendRequest(`sweepstakes/winner/${id}`, "GET");
        setWinner(winner);
      }
    } catch (error) {
      setWinnerError(true);
    }
    setLoading(false);
  };

  const updateName = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      winner.customer = {
        name,
        email: winner.customer.email,
        address: {
          line1,
          line2,
          city,
          state,
          postal_code: postalCode,
        },
      };

      await sendRequest(
        "sweepstakes/winner/address",
        "PUT",
        JSON.stringify(winner)
      );

      setWinner(winner);

      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  const changeName = (e) => {
    setNameError("");
    setName(e.target.value);
  };

  const changeLine1 = (e) => {
    setLine1Error("");
    setLine1(e.target.value);
  };

  const changeLine2 = (e) => {
    setLine2Error("");
    setLine2(e.target.value);
  };

  const changeCity = (e) => {
    setCityError("");
    setCity(e.target.value);
  };

  const changeState = (state) => {
    setStateError("");
    setState(state);
  };

  const changePostalCode = (e) => {
    setPostalCodeError("");
    setPostalCode(e.target.value);
  };

  return (
    <div className="main">
      <Intro
        primary="YOU WON!"
        secondary="congratulations! we just need your shipping information so we can send you your prize."
      />
      {winnerError && (
        <div className="column">
          <h2>SOMETHING WENT WRONG</h2>
          <p className="o64">we couldn't find your prize... so sorry.</p>
        </div>
      )}
      {winner?.items[0] && (
        <div className="column p01">
          <h2>YOUR PRIZE</h2>
          <Item
            title={winner.items[0].title}
            description={winner.items[0].description}
            specifics={winner.items[0].specifics}
            email={winner.customer.email}
            total={"0 (FREE!)"}
          />
        </div>
      )}
      {winner?.customer?.address ? (
        <div className="column p01">
          <h2>YOU'RE ALL SET!</h2>
          <p>we'll ship your name out soon.</p>
        </div>
      ) : (
        <div className="column p01">
          <h2>WHERE YOU WANT IT</h2>
          <p className="o64">
            let us know where you want us to send your name, and we'll make sure
            it gets to you!
          </p>
          <form onSubmit={updateName}>
            <Input
              error={nameError}
              label="full name"
              value={name}
              onChange={changeName}
            />
            <Input
              error={line1Error}
              label="address line 1"
              value={line1}
              onChange={changeLine1}
            />
            <Input
              error={line2Error}
              label="address line 2"
              value={line2}
              onChange={changeLine2}
            />
            <Input
              error={cityError}
              label="city"
              value={city}
              onChange={changeCity}
            />
            <div className="flex">
              <Dropdown
                error={stateError}
                label="state"
                name="state"
                selected={state}
                options={unitedStates}
                onChange={changeState}
              />
              <Input
                error={postalCodeError}
                label="zip code"
                value={postalCode}
                onChange={changePostalCode}
                type="number"
              />
            </div>
            <button type="submit">SUBMIT</button>
          </form>
        </div>
      )}
      <div className="column p01">
        <Link to={"/sweepstakes/live"} className="link">
          BACK TO SWEEPSTAKES
        </Link>
        <a
          className="link"
          href="https://www.tiktok.com/@squidslippers"
          target="_blank"
          rel="noreferrer noopener"
        >
          WATCH ME MAKE IT LIVE
        </a>
      </div>
    </div>
  );
};

export default Winner;
