import React from "react";
import Intro from "../../../components/intro/Intro";
import NameList from "./NameList";

const Manage = () => {
  return (
    <div className="main">
      <Intro
        primary="AAAND WE'RE LIVE!"
        secondary="here's where you can manage the current sweepstakes."
      />
      <div className="column p01">
        <NameList />
      </div>
    </div>
  );
};

export default Manage;
