import React, { useEffect, useState } from "react";
import { socket } from "../../../helpers/socket";
import { useHttp } from "../../../helpers/useHttp";
import css from "./Live.module.css";
import check from "../../../components/radio/check.png";
import Radio from "../../../components/radio/Radio";

const NameList = () => {
  const [retryCount, setRetryCount] = useState(0);
  const [connected, setConnected] = useState(false);

  const previousEntries = JSON.parse(sessionStorage.getItem("entries")) || [];
  const previousOrders = JSON.parse(sessionStorage.getItem("orders")) || [];
  const previousWinners = JSON.parse(sessionStorage.getItem("winners")) || [];

  const [entries, setEntries] = useState(previousEntries);
  const [orders, setOrders] = useState(previousOrders);
  const [winners, setWinners] = useState(previousWinners);
  const [chosen, setChosen] = useState("");

  const [sendEmail, setSendEmail] = useState(
    process.env.NODE_ENV === "production"
  );
  const [sendEmailError, setSendEmailError] = useState(false);

  const [loading, setLoading] = useState(false);

  const { sendRequest } = useHttp();

  useEffect(() => {
    const connect = () => {
      socket.on("connect", () => {
        setConnected(true);

        console.log("connected!");
      });

      socket.on("disconnect", () => {
        setConnected(false);

        console.log("disconnected.");
      });

      socket.on("connect_error", (err) => {
        console.error(`FAIL: ${err?.message}`);

        setRetryCount((oldCount) => {
          console.log(`retryCount: ${oldCount + 1}`);

          return oldCount + 1;
        });

        if (socket.active) {
          console.log("automatically retrying to connect.");
        } else {
          console.log("permanent failure. disconnecting...");
          socket.disconnect();
        }
      });

      socket.on("entry", (entry) => {
        setEntries((oldList) => {
          let newEntries = [entry, ...oldList];
          sessionStorage.setItem("entries", JSON.stringify(newEntries));
          return newEntries;
        });
      });

      socket.on("order", (order) => {
        setOrders((oldOrders) => {
          let newOrders = [...oldOrders, ...order.items];
          sessionStorage.setItem("orders", JSON.stringify(newOrders));
          return newOrders;
        });

        setEntries((oldEntries) => {
          let newEntries = oldEntries.map((entry) => {
            if (order.items.some((item) => item.id === entry.id)) {
              entry.status = "ORDERED";
            }
            return entry;
          });
          sessionStorage.setItem("entries", JSON.stringify(newEntries));
          return newEntries;
        });
      });

      socket.connect();
    };

    if (retryCount < 5) {
      console.log("retrying to connect.");
      connect();
    } else {
    }

    return () => {
      socket?.disconnect();
    };
  }, [retryCount]);

  function deleteEntry(id) {
    setEntries((old) => {
      const newArray = [...old];
      const index = old.findIndex((entry) => entry.id === id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
      sessionStorage.setItem("entries", JSON.stringify(newArray));
      return newArray;
    });
  }

  function deleteWinner(id) {
    setWinners((old) => {
      const newArray = [...old];
      const index = newArray.findIndex((order) => order.id === id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
      sessionStorage.setItem("winners", JSON.stringify(newArray));
      return newArray;
    });
  }

  function deleteOrder(id) {
    setOrders((old) => {
      const newArray = [...old];
      const index = newArray.findIndex((order) => order.id === id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
      sessionStorage.setItem("orders", JSON.stringify(newArray));
      return newArray;
    });
  }

  function chooseRandom() {
    const eligible = entries.filter((entry) => {
      return !entry.status;
    });

    const randomEligible =
      eligible[Math.floor(Math.random() * eligible.length)];

    setChosen(randomEligible);
  }

  async function lockInWinner(e) {
    e.preventDefault();
    setLoading(true);
    try {
      let winner = await sendRequest(
        "sweepstakes/winner",
        "POST",
        JSON.stringify({ winner: chosen, options: { sendEmail } })
      );

      setWinners((oldWinners) => {
        let newWinner = winner.items[0];
        newWinner.status = winner.history?.find(
          (event) => event.event === "SWEEPSTAKE WON"
        ).notified
          ? "EMAILED"
          : "NOT EMAILED";

        let newWinners = [...oldWinners, newWinner];
        sessionStorage.setItem("winners", JSON.stringify(newWinners));
        return newWinners;
      });

      setEntries((oldEntries) => {
        let newEntries = oldEntries.map((entry) => {
          if (winner.id === entry.id) {
            entry.status = "WINNER!";
          }
          return entry;
        });
        sessionStorage.setItem("entries", JSON.stringify(newEntries));
        return newEntries;
      });
    } catch (error) {
      throw {};
    }
    setLoading(false);
  }

  const changeSendEmail = (option) => {
    setSendEmailError("");
    setSendEmail(option);
  };

  return (
    <div className={css.main}>
      <h2>STATS</h2>
      <div className={css.stats}>
        <div className={css.stat}>
          <span style={{ textAlign: "center" }}>ENTRIES</span>
          <strong className={css.number}>{entries.length}</strong>
        </div>
        <div className={css.stat}>
          <span style={{ textAlign: "center" }}>WINNERS</span>
          <strong className={css.number}>{winners.length}</strong>
        </div>
        <div className={css.stat}>
          <span style={{ textAlign: "center" }}>ORDERS</span>
          <strong className={css.number}>{orders.length}</strong>
        </div>
        <div className={css.stat}>
          <span style={{ textAlign: "center" }}>INCOME</span>
          <strong className={css.number}>
            ${orders.reduce((acc, curr) => acc + curr.specifics.total, 0)}
          </strong>
        </div>
      </div>

      <div className="column">
        <h3>WINNERS</h3>

        <button
          disabled={!entries.length > 0}
          className={!entries.length > 0 ? "disabled" : "link"}
          style={{ width: "100%" }}
          onClick={chooseRandom}
        >
          CHOOSE RANDOM WINNER
        </button>

        {chosen && (
          <div
            key={chosen.sweepstakes.entered}
            className={`${css.entry} ${css.chosen} ${
              css[chosen.specifics.color.replace(/\s/g, "")]
            }`}
          >
            <button
              onClick={() => {
                setChosen();
              }}
              className={css.delete}
            >
              X
            </button>
            <div className={css.row}>
              <strong className={css.customization}>
                {chosen.specifics.customization}
              </strong>
              <span className={css.email}>{chosen.sweepstakes.email}</span>
            </div>
            <div className={`${css.row} o64`}>
              <span className={css.specifics}>
                {chosen.specifics.type} • {chosen.specifics.style} •{" "}
                {chosen.specifics.color}
              </span>
              {chosen.status && <span>{chosen.status}</span>}
            </div>
          </div>
        )}

        {chosen && (
          <form onSubmit={lockInWinner}>
            <Radio
              error={sendEmailError}
              onChange={changeSendEmail}
              title="save winner and send email?"
              options={[
                { display: "yes", value: true },
                { display: "no", value: false },
              ]}
              selected={sendEmail}
            />
            <button
              disabled={!chosen}
              className={!chosen ? "disabled" : undefined}
              type="submit"
            >
              {loading ? "LOADING..." : "LOCK IN"}
            </button>
          </form>
        )}

        <ul className={css.list}>
          {winners.length > 0 &&
            winners.map((winner) => {
              return (
                <li
                  key={winner.sweepstakes.entered}
                  className={`${css.entry} ${
                    css[winner.specifics.color.replace(/\s/g, "")]
                  }`}
                  onClick={() => setChosen(winner)}
                >
                  <button
                    onClick={() => {
                      deleteWinner(winner.id);
                    }}
                    className={css.delete}
                  >
                    X
                  </button>
                  <div className={css.row}>
                    <strong className={css.customization}>
                      {winner.specifics.customization}
                    </strong>
                    <span className={css.email}>
                      {winner.sweepstakes.email}
                    </span>
                  </div>
                  <div className={`${css.row} o64`}>
                    <span className={css.specifics}>
                      {winner.specifics.type} • {winner.specifics.style} •{" "}
                      {winner.specifics.color}
                    </span>
                    <span style={{ color: "white" }}>{winner.status}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>

      <div className="columns">
        <div className="column">
          <h3>ENTRIES</h3>
          <ul className={css.list}>
            {entries.length > 0 &&
              entries.map((entry) => {
                return (
                  <li
                    key={entry.id}
                    className={`${css.entry} ${
                      entry?.ordered ? css.ordered : undefined
                    } ${css[entry.specifics.color.replace(/\s/g, "")]}`}
                    onClick={() => setChosen(entry)}
                  >
                    <button
                      onClick={() => {
                        deleteEntry(entry.id);
                      }}
                      className={css.delete}
                    >
                      {entry?.ordered ? (
                        <img className={css.check} src={check} alt="check" />
                      ) : (
                        "X"
                      )}
                    </button>
                    <div className={css.row}>
                      <strong className={css.customization}>
                        {entry.specifics.customization}
                      </strong>
                      <span className={css.email}>
                        {entry.sweepstakes.email}
                      </span>
                    </div>
                    <div className={`${css.row} o64`}>
                      <span className={css.specifics}>
                        {entry.specifics.type} • {entry.specifics.style} •{" "}
                        {entry.specifics.color}
                      </span>
                      {entry.status && <span>{entry.status}</span>}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="column">
          <h3>ORDERS</h3>
          <ul className={css.list}>
            {orders.length > 0 &&
              orders.map((order, index) => (
                <li
                  key={order.sweepstakes.entered}
                  className={`${css.entry} ${
                    css[order.specifics.color.replace(/\s/g, "")]
                  }`}
                  onClick={() => setChosen(order)}
                >
                  <button
                    onClick={() => {
                      deleteOrder(order.id);
                    }}
                    className={css.delete}
                  >
                    X
                  </button>
                  <div className={css.row}>
                    <strong className={css.customization}>
                      {order.specifics.customization}
                    </strong>
                    <span className={css.email}>{order.sweepstakes.email}</span>
                  </div>
                  <span className={`${css.specifics} o64`}>
                    {order.specifics.type} • {order.specifics.style} •{" "}
                    {order.specifics.color}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NameList;
