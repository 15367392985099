import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import IAMContext from "../../../context/iam";
import Intro from "../../../components/intro/Intro";
import Input from "../../../components/input/Input";
import { useHttp } from "../../../helpers/useHttp";

const Login = () => {
  const { setToken } = useContext(IAMContext);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loading, setLoading] = useState(false);

  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  const changeEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPasswordError("");
    setPassword(e.target.value);
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      validate();

      let token = await sendRequest(
        "login",
        "POST",
        JSON.stringify({
          email,
          password,
        })
      );

      setToken(token);
      sessionStorage.setItem("token", token);

      setLoading(false);

      navigate("/admin/dashboard", { replace: true });
    } catch (error) {}
    setLoading(false);
  };

  function validate() {
    try {
      let error = false;

      if (!email) {
        setEmailError("enter your email!");
        error = true;
      }

      if (!password || !typeof password === "string") {
        setPasswordError("enter a valid password!");
        error = true;
      }
    } catch (error) {
      throw {
        code: 400,
        name: "WIRE NAME: INVALID INPUT",
      };
    }
  }

  let disabled = email?.length === 0 || password?.length === 0;

  return (
    <div className="main">
      <Intro
        primary="SECRET, SECRET..."
        secondary="let's get you logged in! if you're me. if you're not me, get out of here! go on! get!"
      />
      <div className="column p01">
        <form onSubmit={login}>
          <Input
            error={emailError}
            label="enter your email"
            onChange={changeEmail}
            type="email"
            value={email}
          />

          <Input
            error={passwordError}
            label="enter your password"
            onChange={changePassword}
            type="password"
            value={password}
          />

          <button
            className={disabled ? "disabled" : null}
            type="submit"
            disabled={disabled}
          >
            {loading ? "loading..." : "HI"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
