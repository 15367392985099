import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";

import apricot from "./pictures/apricot.webp";
import baylee from "./pictures/baylee.webp";
import britain from "./pictures/britain.webp";
import bobomb from "./pictures/bobomb.webp";
import capone from "./pictures/capone.webp";
import cover from "./pictures/cover.webp";
import diglett from "./pictures/diglett.webp";
import earrings from "./pictures/earrings.webp";
import egg from "./pictures/egg.webp";
import elsa from "./pictures/elsa.webp";
import gaten from "./pictures/gaten.webp";
import hackpack from "./pictures/hackpack.webp";
import halo from "./pictures/halo.webp";
import jesus from "./pictures/jesus.webp";
import jigglypuff from "./pictures/jigglypuff.webp";
import mantle from "./pictures/mantle.webp";
import olaf from "./pictures/olaf.webp";
import pearl from "./pictures/pearl.webp";
import pig from "./pictures/pig.webp";
import potter from "./pictures/potter.webp";
import releafer from "./pictures/releafer.webp";
import shrek from "./pictures/shrek.webp";
import squidslippers from "./pictures/squidslippers.webp";
import squidward from "./pictures/squidward.webp";

const Art = () => {
  const pictures = [
    {
      src: potter,
      alt: "harry potter and voldemort",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: britain,
      alt: "a husband and wife looking at each other",
      description:
        "a friend of mine commissioned this wood burning as a christmas gift.",
    },
    {
      src: halo,
      alt: "master chief",
      description: "this commission was one of my earliest wood burnings.",
    },
    {
      src: jesus,
      alt: "jesus",
      description: "i made this as a gift for a family member.",
    },
    {
      src: elsa,
      alt: "elsa",
      description:
        "i made this for fun and gave it away to a stranger on the internet.",
    },
    {
      src: cover,
      alt: "wedding guest book cover",
      description: "a wedding guest book cover a friend commissioned from me.",
    },
    {
      src: gaten,
      alt: "gaten matarazzo",
      description:
        "shoutout to when 'stranger things' was big and i made this.",
    },
    {
      src: capone,
      alt: "capone",
      description: "a wood burning i made to honor my friend's late dog.",
    },
    {
      src: squidslippers,
      alt: "squidslippers",
      description: "my squidslippers logo! i love this little guy!",
    },
    {
      src: hackpack,
      alt: "an abstract infinity symbol with an 'H' hidden inside.",
      description: "the hackpack logo... one of my all-time favorites!",
    },
    {
      src: mantle,
      alt: "m with a circle over it",
      description: "the mantle logo. sleek. refined. calculated.",
    },
    {
      src: releafer,
      alt: "three leaves sprouting from a center point",
      description: "the releafer logo.",
    },
    {
      src: egg,
      alt: "egg",
      description: "a hyper-realistic egg i drew using procreate.",
    },
    {
      src: apricot,
      alt: "apricot",
      description: "a hyper-realistic apricot i drew using procreate.",
    },
    {
      src: pearl,
      alt: "pearl",
      description: "a hyper-realistic pearl i drew using procreate.",
    },
    {
      src: earrings,
      alt: "earrings",
      description:
        "some elements of harmony earrings i made for emma. they didn't turn out so bad... but they didn't turn out nearly how i wanted.",
    },
    {
      src: diglett,
      alt: "diglett",
      description:
        "a diglett sculpture i made out of polymer clay and acryllic paint. it's a plant pot accessory: you bury the flat base in soil, then you have a little diglett living in your plant! how fun!",
      right: true,
    },
    {
      src: jigglypuff,
      alt: "jigglypuff",
      description:
        "a jigglypuff sculpture i made out of polymer clay and acryllic paint. my dogs got to her, which is why she's a little beaten up. but she's still adorable.",
      right: true,
    },
    {
      src: bobomb,
      alt: "bob-omb",
      description:
        "i made this bob-omb before i knew you could bake polymer clay more than once, so it's actually all made out of one piece. if i ever make another one, i'll definitely make it one piece at a time, and it'll look a lot better.",
      right: true,
    },
    {
      src: shrek,
      alt: "shrek",
      description:
        "this is the first and only face i've made out of clay. it's not perfect, but honestly i think i nailed it, especially for my first try!",
      right: true,
    },
    {
      src: pig,
      alt: "pig",
      description:
        "an acrylic painting i made as a commission. i'm not a huge fan of painting.",
    },
    {
      src: baylee,
      alt: "baylee",
      description: "a drawing i made for mrs. utah america herself.",
    },
    {
      src: olaf,
      alt: "olaf",
      description:
        "yeah, that's an actual snowman! it's actually one of several olaf snowmen i've biult over the years. the trick is to build a triangle of snow behind his body to support his head! otherwise, it doesn't last long at ALL. but this lasted a good while!",
    },
    {
      src: squidward,
      alt: "squidward",
      description:
        '"bold and brash" or "belongs in the trash"? (real ones know.)',
    },
  ];

  return (
    <div className="main">
      <Intro
        primary="I 🤍 ART"
        secondary="i make, perform, sell, and give away all kinds of art: from wood
                burning, logo design, and digital art to painting, sculpting,
                drawing, and more—you name it, i've probably tried it! if you
                want to browse my full portfolio and buy some art..."
        tertiary="you're in the right place."
        button={
          <Link className="button" to={"/shop/wire-art"}>
            GET WIRE ART
          </Link>
        }
      />
      <div className="column p01">
        <h2>ART I'VE MADE</h2>
        <p className="o64 ctext">
          i rarely take pictures of my art, but i do sometimes! i'm still in the
          process of photographing some pieces i still have, and i have a few pieces in the works!
        </p>
        <strong>try clicking on one!</strong>
        <Pictures pictures={pictures} />
      </div>
    </div>
  );
};

export default Art;
