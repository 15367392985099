import React from "react";
import css from "./PrivacyPolicy.module.css";

const UL = ({ children, style }) => {
  return (
    <ul className={css.ul} style={style}>
      {children}
    </ul>
  );
};

export default UL;
