import React from "react";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/_accordion/Accordion";

const SoftwareEngineering = () => {
  return (
    <div className="main">
      <Intro
        primary="SOFTWARE ENGINEERING"
        secondary="I have 10+ years of experience designing, building, and marketing software that have served millions of users worldwide. From AI chatbots to custom e-commerce platforms, financial trainers to video games and more—I've worked on some cool stuff. If you want to see the projects i've been working on..."
        tertiary="You're in the right place."
      />

      <div className="column p01">
        <h2>MY SOFTWARE</h2>
        <p className="o64 ctext">
          All of the projects in this section are ones I've imagined, designed,
          and developed myself. Some of them started as practice projects,
          others as passion projects, but they've all grown into useful software
          that I—and, in some cases, others—use often. How fun!
        </p>
        <Accordion title="SQUIDSLIPPERS">
          <p className="o64">
            This website is a place for me to share who I am and what I'm up to.
          </p>
          <p className="o64">
            I love the look and feel of my website; I believe it communicates
            the essence of the squidslippers brand well! But the most impressive
            part is what goes on behind the scenes. This is a full-stack
            application, with a custom-built e-commerce platform that supports
            live sweepstakes giveaways. Hidden away is the admin section of the
            website, where my team and I can log in to securely manage our shop,
            sweepstakes, customer relations, and more.
          </p>
          <p className="o64">But that's for me! You just relax and browse!</p>
          <h4>OPPORTUNITY: PARTNER UP</h4>
          <p className="o64">
            Live sweepstakes giveaways on social media are a popular and
            effective method of increasing reach, brand awareness, engagement,
            follows, and sales. The software I've built enables companies to
            manage and track the effectiveness of live sweepstakes giveaways. In
            the month since I implemented this, I've seen tremendous benefits,
            including an increase in sales, website traffic, follows,
            engagement, and SEO ranking (we're #1, baby!).
          </p>
          <p className="o64">
            I'm looking for another business that wants to help me test, refine,
            and validate this concept.
          </p>
        </Accordion>
        <Accordion title="CLOUD MONAI">
          <p className="o64">
            Cloud Monai is an AI-powered financial trainer that helps
            individuals with inconsistent incomes track, budget, and master
            their money.
          </p>
          <p className="o64">
            Every budgeting software claims to be future-focused, but they
            really just bind you to your past commitments. They're also built
            around rigid monthly schedules and fixed incomes, making it hard for
            anybody with an inconsistent income to play along.
          </p>
          <p className="o64">
            Cloud Monai uses a one-of-a-kind approach to money management that
            lets anybody—even people with inconsistent incomes—track, budget,
            and master their money. And leveraging the power of AI, users can
            reimagine their budget whenever they want, exploring different ways
            to allocate their cash on the fly.
          </p>
          <p className="o64">What financial freedom feels like!</p>
          <h4>OPPORTUNITY: INVEST</h4>
          <p className="o64">
            This is a completely unique approach to personal finance that has
            been shown to increase users' financial literacy, confidence, and
            freedom—not to mention their dollars. If you're looking for a
            high-risk, high-reward early tech startup investment opportunity,
            reach out to me.
          </p>
          <p className="o64">
            If you'd rather wait until we have a working demo and some
            consistent users, check back in around the beginning of October
            2024.
          </p>
        </Accordion>
        <Accordion title="ANGLR">
          <p className="o64">
            Anglr bridges the gap between graduation and employment.
          </p>
          <p className="o64">
            Designed as a tool to help technical school graduates find a job
            more quickly, Anglr automatically tracks the jobs you've applied to,
            lets you create and share your own portfolio to showcase your work,
            and helps you connect with professionals in your industry who can
            help you land a job.
          </p>
          <p>Put your best foot forward with Anglr.</p>
        </Accordion>
        <Accordion title="GROCER EASE">
          <p className="o64">
            Grocer Ease helps anyone and everyone plan and prepare delicious,
            healthy meals.
          </p>
          <p className="o64">
            Designed to take the stress and anxiety out of food preparation,
            Grocer Ease is your virtual companion for all things cooking.
            Regardless of your skill level, you can explore, experiment with,
            and master new techniques, tools, and recipes at your own pace. And
            don't worry—you'll have expert guidance every step of the way!
          </p>
          <p>Make cooking a breeze!</p>
        </Accordion>
        <Accordion title="PWNY EXPRESS">
          <p className="o64">
            The Pwny Express sends me a message whenever my Xbox friends get on
            or off Xbox Live.
          </p>
          <p className="o64">
            Using{" "}
            <a
              href="https://xbl.io/"
              className="inlink"
              target="_blank"
              rel="noreferrer noopener"
            >
              the unofficial Xbox API
            </a>{" "}
            and{" "}
            <a
              className="inlink"
              href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
              target="_blank"
              rel="noreferrer noopener"
            >
              Discord Webhooks
            </a>
            , I built an application that sends me a message whenever my friends
            get on or off Xbox Live. I can even customize the list of games and
            friends it watches out for. It's a hassle-free way to game with my
            friends! I can catch them whenever they're on without them having to
            text me.
          </p>
        </Accordion>
        <Accordion title="POKÉMON STADIUM 3.0">
          <p className="o64">
            You know how Pokémon battles are turn-based? I think that's kind of
            lame. And I was extremely disappointed when Pokémon GO didn't offer
            a way to battle players near you. Two missed opportunities to add
            some excitement, in my opinion.
          </p>
          <code className="o64 pokebubble">What? JAKE is evolving!</code>
          <code className="o64 pokebubble">
            Congratulations! JAKE evolved into a SOFTWARE ENGINEER!
          </code>
          <code className="o64 pokebubble">
            JAKE is developing a Pokémon fighting game that isn't turn-based and
            relies more heavily on strategy and the stats of individual Pokémon
            to win!
          </code>
        </Accordion>
        <Accordion title="MURDER MYSTERY">
          <p className="o64">
            I've developed an approach to murder mystery dinner parties that
            stands out from the rest because it's completely digital, infinitely
            replayable, and it works with any number of players (with no filler
            roles). A Jackbox-style murder mystery dinner party game will be
            available soon!
          </p>
        </Accordion>
        <Accordion title="SKEDGE">
          <p className="o64">
            I want an app where you can write down all of your tasks in a list,
            then drag and drop them onto a calendar. I also want to quickly and
            easily add other things to my calendar, such as random things I
            notice, habits, emotions, etc.
          </p>
          <p>It's hard to explain, but it'll be awesome! Trust me!</p>
        </Accordion>
        <Accordion title="DYOS">
          <p className="o64">
            Do Your Own Science! A tool for the curious. Too many people have
            gotten into the habit of "just googling it" with regards to things
            that are unique to them. "How much sleep do I need?" isn't a
            question for google; it's a question for the scientist in you!
          </p>
          <p className="o64">
            DYOS helps you Do Your Own Science! It walks you through the
            scientific method, helping you formulate hypotheses, formulate
            reliable tests, and track your findings
          </p>
          <p>Take matters into your own hands! And do your own science!</p>
        </Accordion>

        <h2>SOFTWARE I'VE HELPED WITH</h2>
        <p className="o64 ctext">
          All of the projects in this section are ones I've helped with while
          employed by whichever company owned the software. Some of them were
          cool, others were disasterous, but they all taught me a lot!
        </p>
        <Accordion title="ODYSSEY">
          <p className="o64">
            Built as a replacement for{" "}
            <a
              href="https://www.soomolearning.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="inlink"
            >
              Soomo
            </a>
            , Odyssey is a customization to{" "}
            <a
              href="https://www.instructure.com/canvas"
              target="_blank"
              rel="noreferrer noopener"
              className="inlink"
            >
              Canvas
            </a>{" "}
            that transforms virtual textbooks into online playgrounds for
            learning: students can take quizzes, ask questions, explore
            definitions, and more—all without leaving the page.
          </p>
          <h4>THE IMPACT</h4>
          <p className="o64">
            Odyssey is projected to save the company at least $5,000,000 every
            year. Pretty amazing!
          </p>
          <h4>MY ROLE</h4>
          <p className="o64">
            As the project's Lead Front-End Developer & UX/UI Designer, I helped
            architect the software and was responsible for designing and
            developing the project's user interface.
          </p>
          <h4>LANGUAGES & TECHNOLOGIES</h4>
          <p className="o64">JavaScript (React, Node) • CSS • HTML • Webpack</p>
          <p className="o64">Canvas API</p>
          <h4>TEAMWORK</h4>
          <p className="o64">
            I worked on a team of 6 to bring the project to life: my team of 3
            collaborated with 3 people from the company's Canvas department to
            integrate our code with theirs, matching the look, feel, and
            functionality they had implemented.
          </p>
        </Accordion>
        <Accordion title="IRVING & ELLA">
          <p className="o64">
            Irving and Ella are the names of two chatbots that serve Strayer
            University and Capella University (respectively).
          </p>
          <h4>THE IMPACT</h4>
          <p className="o64">
            These tools have handled more than 3,000,000 conversations with over
            250,000 students, boasting an astonishing 89% positive rating from
            students.
          </p>
          <p className="o64">
            Irving specifically has gained worldwide recognition for its
            innovation and usefulness. Google wrote{" "}
            <a
              href="https://edu.google.com/intl/ALL_us/why-google/customer-stories/strategic-education-virtual-assistant/"
              className="inlink"
              target="_blank"
              rel="noreferrer noopener"
            >
              an article praising Irving
            </a>{" "}
            for its ability to handle over 93% of all requests independently,
            and Harvard Business Review agreed to feature{" "}
            <a
              href="https://hbr.org/sponsored/2020/12/ai-and-chatbots-can-help-organizations-meet-rising-customer-expectations"
              className="inlink"
              target="_blank"
              rel="noreferrer noopener"
            >
              sponsored content about Irving
            </a>
            .
          </p>
          <h4>MY ROLE</h4>
          <p className="o64">
            As the project's Architect, I consolidated the two redundant code
            bases into one customizable bot, which streamlined the development
            process and improved the performance and speed. I implemented error
            handling protocols and detailed usage logs, which strengthened the
            bot's reliability and decreased the time spent troubleshooting.
          </p>
          <p className="o64">
            As one of the project's Full-Stack Engineers, I refined and
            standardized the user interface to better match the brand and be
            more user-friendly, which correlated to a 1% increase in overall
            user satisfaction year-over-year.
          </p>
          <h4>LANGUAGES & TECHNOLOGIES</h4>
          <p className="o64">JavaScript (React, Node) • CSS • HTML • Webpack</p>
          <p className="o64"></p>
          <h4>TEAMWORK</h4>
          <p className="o64">
            I worked on this project for more than a year, in which time I
            collaborated with more than 18 people (in and out of my department)
            to bring the vision to life.
          </p>
        </Accordion>
        <Accordion title="ENTERPRISE AI"></Accordion>
        <Accordion title="DIALOG MANAGEMENT CONSOLE"></Accordion>
        <Accordion title="SAFE SELECT"></Accordion>
        <Accordion title="REACHCX / OUTREACH"></Accordion>
        <Accordion title="AT-A-GLANCE DASHBOARD"></Accordion>
        <Accordion title="CUSTOM ACCOUNTING"></Accordion>
        <Accordion title={`"U"`}></Accordion>

        {/* <h2>PLACES I'VE WORKED</h2>
        <i className="o64">ratings are on a scale from -5 to 5.</i>
        <Accordion title="MANTLE EXPERIENCE MANAGEMENT">
          <h4>RATING: 5</h4>
          <h4></h4>
        </Accordion>
        <Accordion title="STRATEGIC EDUCATION, INC.">
          <h4>RATING: -5</h4>
          <p className="o64" style={{ marginTop: "-1rem" }}>
            this was a terrible place to work. i don't even refer this place to
            people i know who are looking for a job.
          </p>
          <h4>JOB DESCRIPTION</h4>
        </Accordion>
        <Accordion title="CUSTOMER DYNAMICS">
          <h4>RATING: 1</h4>
          <p className="o64" style={{ marginTop: "-1rem" }}>
            my coworkers were cool, my workload was awesome
          </p>
          <h4>CULTURE: -2</h4>
          <h4>LEADERSHIP: 1</h4>
          <h4>OPPORTUNITIES: 2</h4>
          <h4>WORKLOAD: 4</h4>
        </Accordion>
        <Accordion title="RETAIL CONSULTING TEAM">
          <h4>OVERALL: 0</h4>
          <h4>CULTURE: -4</h4>
          <h4>LEADERSHIP: -3</h4>
          <h4>OPPORTUNITIES: 2</h4>
          <h4>WORKLOAD: 4</h4>
        </Accordion>
        <Accordion title="SAPA INVESTMENT GROUP">
          <h4>RATING: -5</h4>
        </Accordion>
        <Accordion title="GRAVITATE ONLINE">
          <h4>RATING: -5</h4>
        </Accordion> */}
      </div>
    </div>
  );
};

export default SoftwareEngineering;
