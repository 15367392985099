import React from "react";
import { Link } from "react-router-dom";
import Intro from "../../components/intro/Intro";
import Pictures from "../../components/pictures/Pictures";
// ART
import britain from "../art/pictures/britain.webp";
import apricot from "../art/pictures/apricot.webp";
import pig from "../art/pictures/pig.webp";
import squidslippers from "../art/pictures/squidslippers.webp";
// PHOTOGRPAHY
import raja from "../photography/pictures/raja.jpg";
import feet from "../photography/pictures/feet.webp";
import clouds1 from "../photography/pictures/clouds1.jpg";
import parents from "../photography/pictures/parents.jpg";
import Accordion from "../../components/_accordion/Accordion";

const Home = () => {
  return (
    <div className="main">
      <Intro
        primary="HEY THERE"
        secondary="if you want to learn about anika, art, comedy, eevee, maple, music, photography, psychology, social media, software engineering, storytelling, video games, writing, and other things i love..."
        tertiary="you're in the right place."
        button={
          <Link className="button" to="/sweepstakes/live">
            JOIN LIVE SWEEPSTAKE
          </Link>
        }
      />
      <div className="column p01">
        <h2>SHOP</h2>
        <Accordion title="WIRE ART">
          <p className="o64">
            we're LIVE on tiktok making wire names and jewelry! if you want to
            enter for a chance to win...
          </p>
          <Link className="button" to="/sweepstakes/live">
            JOIN LIVE SWEEPSTAKE
          </Link>
        </Accordion>
        <Accordion title="COLORED PAINCILS">
          <p className="o64">
            the adorable self defense item that packs quite a punch! it's fun
            for (almost) everybody!
          </p>
          <Link className="button" to="/shop/colored-paincils">
            CHECK IT OUT
          </Link>
        </Accordion>

        <h2>EXPLORE</h2>
        <Accordion title="ART">
          <p className="o64">
            i make, perform, sell, and give away all kinds of art: wood burning,
            logo design, digital art, painting, sculpting, drawing, and more—you
            name it, i've (probably) tried it!
          </p>
          <p>
            here's where you can browse my art portfolio and buy some of my
            custom-made art.
          </p>
          <Pictures
            key={1}
            pictures={[
              {
                src: britain,
                alt: "a husband and wife looking at each other",
                description:
                  "a friend of mine commissioned this wood burning as a christmas gift for his wife.",
              },
              {
                src: apricot,
                alt: "apricot",
                description:
                  "a hyper-realistic apricot i drew using procreate.",
              },
              {
                src: squidslippers,
                alt: "squidslippers",
                description: "my squidslippers logo! i love this little guy!",
              },
              {
                src: pig,
                alt: "pig",
                description: "an acrylic painting i made as a commission.",
              },
            ]}
          />
          <Link className="button" to={"/art"}>
            EXPLORE MY ART
          </Link>
        </Accordion>

        <Accordion title="SOFTWARE ENGINEERING">
          <p className="o64">
            i've been in the software engineering and digital marketing space
            for over 11 years. in that time, i've worked on several projects
            that have been seen and used by millions of people worldwide.
          </p>
          <p>
            here's where you can see the software projects i've been working on.
          </p>
          <Link className="button" to="/software-engineering">
            EXPLORE
          </Link>
        </Accordion>

        <Accordion title="PHOTOGRAPHY">
          <p className="o64">
            i'm not a professional or anything, but i do have a nice camera, a
            basic understanding of photography, a lot of free time, and a few
            neat pictures to show for it.
          </p>
          <p>here's where you can browse the pictures i've taken!</p>
          <Pictures
            key={2}
            pictures={[
              {
                src: raja,
                alt: "red, white, and blue betta fish",
                description:
                  "anika and i love betta fish. everyone, this is raja. raja, everyone.",
              },
              {
                src: feet,
                alt: "feet prints in sand",
                description:
                  "getting a picture like this is NOT easy, i'll tell you that much.",
              },
              {
                src: clouds1,
                alt: "clouds",
                description:
                  "(the sky is the artist here; i just took the picture.)",
              },
              {
                src: parents,
                alt: "parents",
                description:
                  "my mom and dad are so cute and great! just look at 'em!",
              },
            ]}
          />
          <Link className="button" to={"/photography"}>
            EXPLORE MY PHOTOGRAPHY
          </Link>
        </Accordion>

        <Accordion title="SOCIAL MEDIA">
          <p className="o64">
            buzzfeed wrote an article about me. i somehow have 150k+ followers
            on tiktok. f***jerry paid me $50. james the mormon owes me a
            favor...
          </p>
          <a
            href="https://www.youtube.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            TIKTOK
          </a>
          <a
            href="https://www.youtube.com/@squidslippers"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            YOUTUBE
          </a>
          <a
            href="https://www.linkedin.com/in/jacob-moreno/"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            LINKEDIN
          </a>
          <a
            href="https://www.instagram.com/squidslippers/"
            target="_blank"
            rel="noreferrer noopener"
            className="link"
          >
            INSTAGRAM
          </a>
        </Accordion>
        <Accordion title="VIDEO GAMES">
          <p className="o64">
            i love [ playing / watching / making ] video games!
          </p>
          <p>
            here's where you can see what i'm up to in the video games i'm
            playing.
          </p>
          <Link className="button" to={"/video-games"}>
            EXPLORE
          </Link>
        </Accordion>
      </div>
    </div>
  );
};

export default Home;
