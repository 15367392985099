import { io } from "socket.io-client";

export const socket = io(
  process.env.NODE_ENV === "production"
    ? "https://backend-dot-squidslippers.wm.r.appspot.com"
    : "http://localhost:8080",
  {
    autoConnect: false,
  }
);
// export const socket = io("http://localhost:8080", {
//   autoConnect: false,
// });
