import React, { forwardRef } from "react";
import css from "./Input.module.css";

const Input = forwardRef(
  ({ autoFocus, children, error, label, type, value, onChange }, ref) => {
    const inputClass = `${css.input} ${value ? null : css.inputBlank} ${
      error ? css.inputError : null
    }`;
    const labelClass = `${css.label} ${value ? null : css.labelBlank} ${
      error ? css.labelError : null
    }`;

    return (
      <div className={css.main}>
        <label className={labelClass}>{error ? error : label}</label>
        <input
          ref={ref}
          className={inputClass}
          type={type || "text"}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
        />
        {children && <div className={css.children}>{children}</div>}
      </div>
    );
  }
);

export default Input;
