import React, { useContext } from "react";
import CartContext from "../../context/cart";
import { Link, useLocation } from "react-router-dom";
import css from "./Cart.module.css";

const Cart = () => {
  let location = useLocation();
  const { cart } = useContext(CartContext);
  if (cart?.length > 0 && !["/shop/cart", "/shop/checkout"].includes(location.pathname)) {
    return (
      <Link to="/shop/cart" className={css.main}>
        {cart.length}
      </Link>
    );
  } else return null;
};

export default Cart;
