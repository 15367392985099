import React from "react";
import Intro from "../../components/intro/Intro";
import Accordion from "../../components/_accordion/Accordion";
import { Link } from "react-router-dom";

const VideoGames = () => {
  return (
    <div className="main">
      <Intro
        primary="VIDEO GAMES"
        secondary="i really enjoy video games—playing them, watching them, making them. the concept of combining so many kinds of art then letting somebody else bring it all to life however they want to is so cool! if you want to check out what i'm up to in the games i'm playing..."
        tertiary="you're in the right place."
      />
      <div className="column p01">
        <h2>GAMES I PLAY</h2>
        <p className="o64 ctext">
          i've been playing video games—casually—my whole life. i'm bad at a
          few, good at most, and great at a few. but i'm still convinced that
          had my mom let me sit down and play, i'd be a multi-millionaire gaming
          streamer right now! (but who isn't?)
        </p>
        <Accordion title="ANIMAL CROSSING">
          <p className="o64">
            one of my all-time favorite games. but really i should say it's one
            of <i>our</i> favorites because anika loves it too. we share an
            island, and we play it ALL the time.
          </p>
          <p>
            here's where you can learn about my character, island, neighbors,
            designs, and more.
          </p>
          <Link className="button" to="animal-crossing">
            CHECK IT OUT
          </Link>
        </Accordion>
        <Accordion soon title="SUPER SMASH BROS. ULTIMATE"></Accordion>
        <Accordion soon title="MARIO KART 8 DELUXE"></Accordion>
        <Accordion soon title="ROCKET LEAGUE"></Accordion>
        <Accordion soon title="ZELDA (TOTK)"></Accordion>
        <Accordion soon title="HOLLOW KNIGHT"></Accordion>
        <Accordion soon title="RED DEAD REDEMPTION II"></Accordion>
        <Accordion soon title="FORTNITE"></Accordion>

        <h2>PLAY WITH ME</h2>
        <Accordion title="NINTENDO SWITCH">
          <p className="o64">
            <strong>friend code:</strong> SW-5569-6091-6023
          </p>
        </Accordion>
        <Accordion title="DISCORD">
          <p className="o64">
            <strong>username:</strong> _squidslippers_
          </p>
        </Accordion>
        <Accordion title="GAMEPIGEON">
          <p className="o64">
            <strong>number:</strong> (801) 867-8774
          </p>
        </Accordion>
        <Accordion title="XBOX">
          <p className="o64">
            <strong>username:</strong> squidslippers
          </p>
        </Accordion>
        <Accordion soon title="EPIC GAMES"></Accordion>
        <Accordion soon title="STEAM"></Accordion>

        <h2>PWNY EXPRESS</h2>
        <p className="o64 ctext">
          the pwny express is a software application i built for myself. it
          continuously scans{" "}
          <a
            href="https://xbl.io/"
            target="_blank"
            rel="noreferrer noopener"
            className="inlink"
          >
            the unofficial xbox api
          </a>{" "}
          and sends me a message on discord whenever my friends get on or off
          xbox live. (i configure a list of games and friends for it to watch
          for, and it only watches those.)
        </p>
        <p className="o64 ctext">
          i'm working on building out a user interface so other people can sign
          up and use this tool, too!
        </p>
      </div>
    </div>
  );
};

export default VideoGames;
