import React, { useState } from "react";
import Intro from "../../components/intro/Intro";
import css from "./Dashboard.module.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [live, setLive] = useState(false);

  const toggleLive = () => {
    setLive((old) => !old);
  };

  return (
    <div className="main">
      <Intro
        primary="WELCOME IN"
        secondary="you've made it inside. you've either been invited or you're a decent hacker—either way, you're doing a great job."
      />
      <h2>SHOP</h2>
      <div className="column p01">
        <Link to={"/admin/live/manage"} className="link">
          <h3>MANAGE LIVE</h3>
        </Link>
        <Link to={"/admin/live/view"} className="link">
          <h3>VIEW LIVE</h3>
        </Link>
        {/* <Link to={"admin/orders"} className="link">
          <h3>MANAGE ORDERS</h3>
        </Link> */}
      </div>
    </div>
  );
};

export default Dashboard;
