import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const IAMContext = createContext({
  logOut: () => {},
  setToken: () => {},
  token: {},
});

export const IAMContextProvider = (props) => {
  const previousToken = sessionStorage.getItem("token");
  const [token, setToken] = useState(previousToken || "");

  const navigate = useNavigate();

  const logOut = () => {
    setToken("");
    sessionStorage.removeItem("token");
    navigate("/");
  };

  const context = {
    logOut,
    setToken,
    token,
  };

  return (
    <IAMContext.Provider value={context}>{props.children}</IAMContext.Provider>
  );
};

export default IAMContext;
